const copyPlugin = {
  // @Required
  // plugin name
  name: 'copy',

  // @Required
  // data display
  display: 'command',
  command: 'copy-command',

  // @Required
  // add function - It is called only once when the plugin is first run.
  add() {},

  async action() {
    const editorEl = document.getElementsByClassName('sun-editor-editable')[0];
    if (editorEl) {
      (editorEl as HTMLElement).focus();
      try {
        // Get the selection and its range
        const selection = window.getSelection() ?? null;
        if (!selection?.rangeCount) return;

        const range = selection.getRangeAt(0);
        const selectedContent = range.cloneContents();

        // Convert the selected content to HTML string
        const div = document.createElement('div');
        div.appendChild(selectedContent);
        // remove leading empty divs
        const htmlContent = div.innerHTML.replace(
          /^(<div[^>]*>\s*<\/div>\s*)+/,
          '',
        );

        // Use Clipboard API to copy the selected HTML content
        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], {type: 'text/html'}),
            'text/plain': new Blob([selection.toString()], {
              type: 'text/plain',
            }),
          }),
        ]);
        console.log('Selected content copied to clipboard.');
      } catch (err) {
        console.error('Failed to copy content: ', err);
      }
    }
  },
};

export default copyPlugin;
