export const transformToSelectItems = <T, K extends keyof T>(
  list: T[] | undefined,
  labelKey: K,
  valueKey: K,
) => {
  return (
    list?.map(item => ({
      label: item[labelKey] as unknown as string,
      value: item[valueKey] as unknown as string,
    })) || []
  );
};
