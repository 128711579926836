// Helper function to remove leading empty divs using replaceAll
function removeLeadingEmptyDivs(htmlContent: string): string {
  // Use a regular expression to match leading empty <div> elements
  return htmlContent.replace(/^(<div[^>]*>\s*<\/div>\s*)+/, '');
}

const pastePlugin = {
  name: 'paste',
  display: 'command',
  command: 'paste-plugin-command',

  add() {},
  async action() {
    const editorEl = document.getElementsByClassName('sun-editor-editable')[0];
    if (editorEl) {
      try {
        // Focus on the editor to ensure the paste operation occurs in the right place
        (editorEl as HTMLElement).focus();

        // Read the clipboard data
        const clipboardItems = await navigator.clipboard.read();

        // Handle HTML content if available
        const htmlItem = clipboardItems.find(item =>
          item.types.includes('text/html'),
        );
        if (htmlItem) {
          const htmlBlob = await htmlItem.getType('text/html');
          const htmlContent = await htmlBlob.text();

          // Clean up any unwanted leading divs
          const cleanedHtmlContent = removeLeadingEmptyDivs(htmlContent);

          // Insert the cleaned HTML content into the editor
          document.execCommand('insertHTML', false, cleanedHtmlContent);
          console.log('HTML content pasted into editor.');
          return; // Exit after handling HTML
        }

        // Handle plain text content if available
        const textItem = clipboardItems.find(item =>
          item.types.includes('text/plain'),
        );
        if (textItem) {
          const plainTextBlob = await textItem.getType('text/plain');
          const plainTextContent = await plainTextBlob.text();

          // Insert plain text content into the editor
          document.execCommand('insertText', false, plainTextContent);
          console.log('Plain text content pasted into editor.');
          return; // Exit after handling plain text
        }
      } catch (err) {
        console.error('Failed to paste content: ', err);
      }
    }
  },
};

export default pastePlugin;
