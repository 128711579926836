import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FullStoryAPI} from 'react-fullstory';
import CustomDialog from './components/common/CustomDialog';
import {getConfig} from './lib/utils';
import {
  fetchUdfDetails,
  getUserData,
  getUserSettings,
} from './store/actions/userActions';
import {ProtectedRoute} from './routes/ProtectedRouter';
import {useQueryKeys} from './hooks/useQueryKeys';
import {fetchUserByUserKey} from './services/users';
import {useSessionStorage} from './hooks/useSessionStorage';
import {getZenDeskToken} from './services/application';
import {useSnackbar} from './context/SnackbarContext';
import {
  Logout,
  Layout,
  CommunicateWrapper,
  Home,
  ErrorBoundaryWrapper,
  Communication,
  EditPaymentMethods,
  MailBox,
  Accounts,
  Login,
  FavoriteAccountsHandler,
  ExpectedPayments,
  FollowUp,
  PendingPayments,
  DraftDashboard,
  Dashboard,
  Activities,
  HandleInquiriesByUserChildRoutes,
  AccountOverview,
  PageNotFound,
  Unauthorized,
  ActivityTemplate,
  ActivityTemplateDelete,
  ActivityTemplateFormContainer,
  MaintainReportDialog,
  ReasonCode,
  ReasonCodeDelete,
  ReasonCodeFormContainer,
  SalesRep,
  ReportingGroups,
  ReportingGroupsFormContainer,
  ReportFormContainer,
  ReportDelete,
  Report,
  MessageSettings,
  MessageSettingsFormContainer,
  Company,
} from './lazyComponents';
import {SetAuth} from './components/auth/set-auth/SetAuth';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {userKey} = useQueryKeys();
  const {groupKey} = getUserData() || {};
  const {environment} = getConfig();
  const {applicationOptions} = useSelector(
    store => store.usersReducer.userSettings,
  );
  const showSnackbar = useSnackbar();

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_, setSessionStorage] = useSessionStorage('profile');

  const getUserDataDetails = () => {
    // UPDATE Local storage "profile" by bringing data from api
    if (userKey && groupKey) {
      dispatch(getUserSettings({userKey, groupKey}, showSnackbar));
    }
  };

  const getUdfDetails = async () => {
    dispatch(fetchUdfDetails({groupKey}, showSnackbar));
  };

  const loadNewRelicScript = () => {
    const script = document.createElement('script');
    script.src = `/relic/${environment}.js`;
    script.async = true;

    script.onload = () => {
      const profile = JSON.parse(sessionStorage.getItem('profile'));
      if (profile && window.newrelic) {
        window.newrelic.setCustomAttribute('userId', profile.userId);
        window.newrelic.setCustomAttribute('userName', profile.username);
      } else {
        console.log('Profile not found or New Relic not available');
      }
    };

    script.onerror = () => {
      console.log('Failed to load New Relic script');
    };

    document.head.appendChild(script);
  };

  const loadZendeskScript = async chatAgent => {
    const {data} = await getZenDeskToken({clientType: 1});
    const {token, url} = data;
    const script = document.createElement('script');

    const scriptId = 'ze-snippet';

    switch (chatAgent) {
      case 1: {
        // Display Web Widget
        const script1 = document.createElement('script');
        script1.setAttribute('id', scriptId);
        script1.setAttribute('src', url);

        const script2 = document.createElement('script');
        script2.textContent = `zE('messenger', 'loginUser', function (callback) { callback('${token}');});`;

        document.head.appendChild(script1);
        document.head.appendChild(script2);
        break;
      }
      case 2: {
        const script1 = document.createElement('script');
        script1.setAttribute('id', scriptId);
        script1.setAttribute('src', url);

        const script2 = document.createElement('script');
        script2.textContent = `window.zESettings = {
        webWidget: {
          authenticate: {
            chat: {
              jwtFn: function (callback) {
                callback('${token}');
              }
            }
          },
        }
      };`;

        document.head.appendChild(script1);
        document.head.appendChild(script2);
        break;
      }

      default:
        break;
    }

    document.body.appendChild(script);
  };

  useEffect(() => {
    if (!sessionStorage.getItem('profile')) {
      navigate('/login');
    } else {
      getUserDataDetails();
      const profile = JSON.parse(sessionStorage.getItem('profile'));
      FullStoryAPI('identify', profile.sessionUserKey, {
        session_user_name: profile.sessionUserName,
        user_name: profile.userName,
        account_name: profile.accountName,
        host_name: profile.hostName,
        user_key: profile.userKey,
      });
    }
    // Load relic script if environment is present and we are not running on localhost
    if (environment) {
      loadNewRelicScript();
    }
  }, []);

  useEffect(() => {
    if (applicationOptions && applicationOptions.chatAgent > 0) {
      loadZendeskScript(applicationOptions.chatAgent);
    }
  }, [applicationOptions?.chatAgent]);

  useEffect(() => {
    if (groupKey) {
      getUdfDetails();
    }
  }, [groupKey]);

  const updateSessionUserInfo = async newUserKey => {
    const {data} = await fetchUserByUserKey({userKey: newUserKey});
    const {userAccountKey, userName} = data.user;
    const profile = JSON.parse(sessionStorage.getItem('profile'));
    if (profile) {
      profile.userId = userAccountKey;
      profile.userKey = newUserKey;
      profile.username = userName;
      if (window.newrelic) {
        window.newrelic.setCustomAttribute('userId', userAccountKey);
        window.newrelic.setCustomAttribute('userName', userName);
      } else {
        console.log('Newrelic not found');
      }
      setSessionStorage(profile);
    }
  };

  useEffect(() => {
    const profile = JSON.parse(sessionStorage.getItem('profile'));
    if (profile && userKey) {
      if (profile.userKey === userKey) {
        return;
      }
      updateSessionUserInfo(userKey);
    }
  }, [userKey]);

  useEffect(() => {
    sessionStorage.removeItem('customFilterQuery');
  }, [location]);
  // TODO: Check if we can think of better approach to use key but avoid parent render which we render child
  const keyForCommunicationRoutes = location.pathname.includes('/activity')
    ? location.pathname.replace('/activity', '')
    : location.pathname;

  return (
    <div className="">
      <CustomDialog />
      <div id="main">
        <Routes>
          <Route
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route path="/" element={<Navigate replace to="/messages" />} />
            <Route path="home" element={<Home />}>
              <Route path="activity" element={<CommunicateWrapper />} />
            </Route>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="/messages">
              <Route
                path="/messages"
                element={<Navigate replace to="/messages/mail-not-filed" />}
              />
            </Route>
            <Route
              path="messages/mail-not-filed"
              element={<MailBox isMailNotFiled />}
            >
              <Route path="activity" element={<CommunicateWrapper />} />
            </Route>
            <Route
              path="messages/expected-payments"
              element={<ExpectedPayments />}
            >
              <Route path="activity" element={<CommunicateWrapper />} />
            </Route>
            <Route
              path="messages/pending-payment-not-reconciled"
              element={<PendingPayments />}
            >
              <Route path="activity" element={<CommunicateWrapper />} />
            </Route>
            <Route
              path="messages/pending-payment-declined-during-last-7-days"
              element={<PendingPayments />}
            >
              <Route path="activity" element={<CommunicateWrapper />} />
            </Route>
            <Route path="messages/followup-due-today" element={<FollowUp />}>
              <Route path="activity" element={<CommunicateWrapper />} />
            </Route>
            <Route path="messages/followup-past-due" element={<FollowUp />}>
              <Route path="activity" element={<CommunicateWrapper />} />
            </Route>

            <Route path="messages/draft-activity" element={<DraftDashboard />}>
              <Route path="activity" element={<CommunicateWrapper />} />
            </Route>
            <Route
              path="messages/:pageTitles"
              element={
                <ErrorBoundaryWrapper>
                  <Communication key={keyForCommunicationRoutes} />
                </ErrorBoundaryWrapper>
              }
            >
              <Route path="activity" element={<CommunicateWrapper />} />
            </Route>
            <Route path="activities" element={<Activities />} />
            <Route
              path="activities/:pageTitle"
              element={
                <ErrorBoundaryWrapper>
                  <Communication key={keyForCommunicationRoutes} />
                </ErrorBoundaryWrapper>
              }
            >
              <Route path="activity" element={<CommunicateWrapper />} />
            </Route>
            <Route
              path="allactivities"
              element={
                <ErrorBoundaryWrapper>
                  <Communication key={keyForCommunicationRoutes} />
                </ErrorBoundaryWrapper>
              }
            >
              <Route path="activity" element={<CommunicateWrapper />} />
            </Route>
            <Route path="accounts">
              <Route path="all-accounts" element={<Accounts />}>
                <Route path="activity" element={<CommunicateWrapper />} />
              </Route>
            </Route>
            <Route
              path="favorite-accounts"
              element={<FavoriteAccountsHandler />}
            />
            <Route path="inquiries">
              <Route path="by-user">
                <Route
                  path="/inquiries/by-user/*"
                  element={<HandleInquiriesByUserChildRoutes />}
                />
              </Route>
            </Route>
            <Route path="/account-overview" element={<AccountOverview />}>
              <Route path="activity" element={<CommunicateWrapper />} />
            </Route>
            <Route path="/customer-info">
              <Route
                path="/customer-info/edit-payment-method"
                element={<EditPaymentMethods />}
              />
            </Route>
            {/* Admin Routes */}
            <Route path="admin">
              <Route path="master-files">
                <Route path="activity-template" element={<ActivityTemplate />}>
                  <Route
                    path="delete/:ruleViolationKey"
                    element={<ActivityTemplateDelete />}
                  />
                  <Route path="add" element={<ActivityTemplateFormContainer />}>
                    <Route path="report" element={<MaintainReportDialog />}>
                      <Route path="add" element={<ReportFormContainer />} />
                      <Route
                        path="edit/:reportKey"
                        element={<ReportFormContainer />}
                      />
                      <Route
                        path="delete/:reportKey"
                        element={<ReportDelete />}
                      />
                    </Route>
                  </Route>
                  <Route
                    path="edit/:ruleViolationKey"
                    element={<ActivityTemplateFormContainer />}
                  >
                    <Route path="report" element={<MaintainReportDialog />}>
                      <Route path="add" element={<ReportFormContainer />} />
                      <Route
                        path="edit/:reportKey"
                        element={<ReportFormContainer />}
                      />
                      <Route
                        path="delete/:reportKey"
                        element={<ReportDelete />}
                      />
                    </Route>
                  </Route>
                </Route>
                <Route path="reason-code" element={<ReasonCode />}>
                  <Route
                    path="delete/:disputeCodeKey"
                    element={<ReasonCodeDelete />}
                  />
                  <Route path="add" element={<ReasonCodeFormContainer />} />
                  <Route
                    path="edit/:disputeCodeKey"
                    element={<ReasonCodeFormContainer />}
                  />
                </Route>
                <Route path="sales-rep" element={<SalesRep />} />
                <Route path="reporting-groups" element={<ReportingGroups />}>
                  <Route
                    path="add"
                    element={<ReportingGroupsFormContainer />}
                  />
                  <Route
                    path="edit/:reportingGroupKey"
                    element={<ReportingGroupsFormContainer />}
                  />
                </Route>
                <Route path="message-settings" element={<MessageSettings />}>
                  <Route
                    path="edit/:stdAlertCodeID"
                    element={<MessageSettingsFormContainer />}
                  />
                </Route>
                <Route path="report" element={<Report />}>
                  <Route path="add" element={<ReportFormContainer />} />
                  <Route
                    path="edit/:reportKey"
                    element={<ReportFormContainer />}
                  />
                  <Route path="delete/:reportKey" element={<ReportDelete />} />
                </Route>
                <Route path="company" element={<Company />} />
              </Route>
            </Route>
            {/* Admin Routes */}
            <Route path="*" element={<PageNotFound />} />
          </Route>
          <Route path="/unauthorized-user" element={<Unauthorized />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/auth" element={<p>Auth</p>} />
          <Route path="/setauth" element={<SetAuth />} />
          <Route path="/auth/set-token" element={<p>Auth Set token route</p>} />
        </Routes>
      </div>
    </div>
  );
}

App.defaultProps = {
  modal: {
    modalSize: 'lg',
    show: false,
  },
};
export default App;
