import React, {Suspense, ComponentType} from 'react';
import {CircularProgress} from '@mui/material';
import {Box} from './ui/layouts';

const withSuspense =
  <P extends object>(Component: ComponentType<P>): ComponentType<P> =>
  (props: P) =>
    (
      <Suspense
        fallback={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh"
          >
            <CircularProgress />
          </Box>
        }
      >
        <Component {...props} />
      </Suspense>
    );

export default withSuspense;
