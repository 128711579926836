const cutPlugin = {
  name: 'cut',
  display: 'command',
  command: 'cut-plugin-command',

  add() {},
  async action() {
    const editorEl = document.getElementsByClassName('sun-editor-editable')[0];
    if (editorEl) {
      try {
        (editorEl as HTMLElement).focus();
        // Get the selection and its range
        const selection = window.getSelection();
        if (!selection?.rangeCount) return;

        const range = selection.getRangeAt(0);
        const selectedContent = range.cloneContents();

        // Convert the selected content to HTML string
        const div = document.createElement('div');
        div.appendChild(selectedContent);
        const htmlContent = div.innerHTML.replace(
          /^(<div[^>]*>\s*<\/div>\s*)+/,
          '',
        );
        // Use Clipboard API to copy the selected HTML content
        await navigator.clipboard.write([
          new ClipboardItem({
            'text/html': new Blob([htmlContent], {type: 'text/html'}),
            'text/plain': new Blob([selection.toString()], {
              type: 'text/plain',
            }),
          }),
        ]);

        // Remove the selected content from the editor
        range.deleteContents();

        console.log('Selected content cut and copied to clipboard.');
      } catch (err) {
        console.error('Failed to cut content: ', err);
      }
    }
  },
};

export default cutPlugin;
