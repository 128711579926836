import {useRef} from 'react';

import SunEditorCore from 'suneditor/src/lib/core';
import SunEditor from 'suneditor-react';
import DOMPurify from 'dompurify';
import selectSnippet from './sun-editor/custom-plugins/selectSnippet';
import copy from './sun-editor/custom-plugins/copy';
import cut from './sun-editor/custom-plugins/cut';
import paste from './sun-editor/custom-plugins/paste';
import selectAll from './sun-editor/custom-plugins/selectAll';
import 'suneditor/dist/css/suneditor.min.css';
import insertDate from './sun-editor/custom-plugins/insertDate';

export interface TextEditorProps {
  textEditorValue: {textValue: string; htmlValue: string};
  className?: string;
  onTextEditorValueChange: (value: {
    textValue: string;
    htmlValue: string;
  }) => void;
  toolbarId?: string;
  disable?: boolean;
  hideToolbar?: boolean;
  buttonList?:
    | string[]
    | {
        name: string;
        dataCommand: string;
        buttonClass: string;
        title: string;
        dataDisplay: string;
        innerHTML: string;
      }[]
    | any[];
  height?: string;
}

export const TextEditor: React.FC<TextEditorProps> = ({
  onTextEditorValueChange,
  textEditorValue,
  disable = false,
  hideToolbar = false,
  buttonList = [],
  height,
}) => {
  const editor = useRef<any>();
  const editorDefaultHeight = '500px';
  const handleEditorChange = (htmlValue: string) => {
    const textValue = editor.current?.getText();
    onTextEditorValueChange({textValue, htmlValue});
  };

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };
  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const loadAllPlugins = () => {
    const editorInstance = editor.current.core;
    editorInstance.plugins = {
      ...editorInstance.plugins,
      selectSnippet,
      copy,
      cut,
      paste,
      selectAll,
      insertDate,
    };
  };

  const sanitizedHtmlContent = DOMPurify.sanitize(textEditorValue.htmlValue);
  return (
    <SunEditor
      placeholder="Please type here..."
      disable={disable}
      autoFocus
      height={height ?? editorDefaultHeight}
      setContents={sanitizedHtmlContent ?? ''}
      hideToolbar={hideToolbar}
      onLoad={loadAllPlugins}
      setOptions={{
        plugins: editor?.current?.core.plugins,
        buttonList,
        attributesWhitelist: {
          table: 'border|cellpadding|cellspacing|width|height|class|style',
          tr: 'style|bgcolor',
          td: 'colspan|rowspan|style|width||bgcolor',
          th: 'colspan|rowspan|style|width|height|bgcolor',
          p: 'style|class',
          div: 'style|class',
          blockquote: 'cite|style',
          a: 'style|href',
        },
      }}
      getSunEditorInstance={getSunEditorInstance}
      onChange={handleEditorChange}
    />
  );
};

export default TextEditor;
