import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import jwt_decode from 'jwt-decode';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import PrimeReact from 'primereact/api';
import FullStory from 'react-fullstory';
import {LicenseInfo} from '@mui/x-license-pro';
import {ProSidebarProvider} from 'react-pro-sidebar';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import App from './App';
import {ThemeProvider} from './ui';
import ErrorBoundaryWrapper from './components/error-boundary/ErrorBoundaryWrapper';
import {SnackbarProvider} from './context/SnackbarContext';

PrimeReact.ripple = true;

if (window.location.href.includes('set-token')) {
  const url = new URL(window.location.href);
  const query = url.searchParams;
  const res = {
    idToken: query.get('idToken'),
    environmentName: query.get('environmentName'),
    groupKey: query.get('groupKey'),
    sessionId: query.get('sessionId'),
    userId: query.get('userId'),
    username: query.get('username'),
    userKey: query.get('userKey'),
    sessionUserId: query.get('sessionUserId'),
    sessionUserName: query.get('sessionUserName'),
    sessionUserKey: query.get('sessionUserKey'),
    isAdmin: query.get('isAdmin').toLowerCase() === 'true',
    userType: query.get('sessionUserType'),
    sessionOemName: query.get('sessionOemName'),
    sessionMenuVersion: query.get('sessionMenuVersion'),
    sessionSupportUserGuidID: query.get('sessionSupportUserGuidID'),
    sessionAppVersion: query.get('sessionAppVersion'),
    accountName: query.get('accountName'),
    windowName: query.get('windowName'),
    hostName: query.get('hostName'),
    sessionViewGroup: query.get('sessionViewGroup'),
    sessionNACMSupport: query.get('SessionNACMSupport'),
  };

  if (query.get('environmentName')) {
    window.sessionStorage.setItem('environmentName', res.environmentName);
  }

  if (res.idToken) {
    window.sessionStorage.setItem('idToken', res.idToken);

    const {idToken} = res;
    const dateNow = new Date();
    if (idToken) {
      const jwt = jwt_decode(res.idToken);
      if (jwt.exp >= dateNow.getTime() / 1000) {
        const user = {
          userId: res.userId,
          username: res.username,
          userKey: res.userKey,
          sessionUserId: res.sessionUserId,
          sessionUserName: res.sessionUserName,
          sessionUserKey: res.sessionUserKey,
          groupKey: res.groupKey,
          sessionId: res.sessionId,
          isAdmin: res.isAdmin,
          userType: res.sessionUserType,
          isAuthenticated: true,
          sessionOemName: res.sessionOemName,
          sessionMenuVersion: res.sessionMenuVersion,
          sessionSupportUserGuidID: res.sessionSupportUserGuidID,
          sessionAppVersion: res.sessionAppVersion,
          accountName: res.accountName,
          windowName: res.windowName,
          sessionViewGroup: res.sessionViewGroup,
          hostName: res.hostName,
          sessionNACMSupport: res.sessionNACMSupport,
        };
        window.sessionStorage.setItem('profile', JSON.stringify(user));
      } else {
        window.sessionStorage.removeItem('profile');
      }
    }
    window.location = '#/';
  }
}

try {
  const idToken = window.sessionStorage.getItem('idToken');
  const dateNow = new Date();
  if (idToken) {
    const jwt = jwt_decode(idToken);
    if (jwt.exp >= dateNow.getTime() / 1000) {
      window.user = JSON.parse(sessionStorage.getItem('profile'));
    } else {
      window.sessionStorage.removeItem('profile');
    }
  }
} catch {}

LicenseInfo.setLicenseKey(
  '75e2d00821a409b1e678265dab111a9aTz02NzI2OSxFPTE3MTY2MzkyNDAzMzMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider>
          <SnackbarProvider>
            <ErrorBoundaryWrapper showLogoutButton>
              <ProSidebarProvider>
                <App />
                <FullStory org="G2QXT" />
              </ProSidebarProvider>
            </ErrorBoundaryWrapper>
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
reportWebVitals();
