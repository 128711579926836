import {Alert, Snackbar as MuiSnackbar, SnackbarProps} from '@mui/material';
import {Typography} from '../../displays';

export type SnackbarType = 'error' | 'success' | 'warning' | 'info';
export interface ISnackbarProps extends SnackbarProps {
  anchorOrigin?: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'right' | 'center';
  };
  onClose?: () => void;
  message: string;
  title: string;
  type: SnackbarType;
  open: boolean;
  autoHideDuration?: number | null;
}

export default function Snackbar({
  anchorOrigin = {vertical: 'top', horizontal: 'right'},
  onClose = () => {},
  message = '',
  open = false,
  autoHideDuration = 4000,
  title = '',
  type = 'success',
  ...otherProps
}: ISnackbarProps) {
  return (
    <MuiSnackbar
      anchorOrigin={anchorOrigin}
      open={open}
      onClose={onClose}
      key={anchorOrigin.vertical + anchorOrigin.horizontal}
      message={message}
      autoHideDuration={autoHideDuration}
      {...otherProps}
    >
      <Alert severity={type} variant="filled" sx={{width: '100%'}}>
        <div className="d-flex flex-column p-1 ">
          <Typography variant="body1" fontWeight={800}>
            {title}
          </Typography>
          <Typography variant="body1"> {message}</Typography>
          <Typography variant="body1" />
        </div>
      </Alert>
    </MuiSnackbar>
  );
}
