import {actionTypes} from '../constants/actionTypes';

const initialState = {
  activitiesSkip: 0,
  activities: [],
  fetchingActivities: false,
  fetchingActivitiesFailed: false,
  fetchedActivities: false,
  loading: false,
  count: 0,
  nextLink: '',
  orderBy: 'dateEntered',

  activityInfo: null,
  activityLoading: false,
  activityReportInfo: [],
  activityAttachments: [],
  activityTypeList: [],
  activityTransactions: [],
  transactionCount: 0,
  transactionNextLink: '',
  transactionSelectedRows: [],
  templateDropdown: [],
  contactsList: [],
  personalContactList: [],
  fetchingActivitiesContactsList: false,
  fetchingActivitiesContactsListFailed: false,
  fetchedActivitiesContactsList: false,

  addingContact: false,
  addingContactFailed: false,
  addedContact: false,
  addedPersonalContact: false,

  fetchingContacts: false,
  fetchedContacts: false,
  fetchingContactsFailed: false,
  contactsCount: 0,
  contacts: [],

  updatingContact: false,
  updatedContact: false,
  updatingContactFailed: false,

  deletingContact: false,
  deletedContact: false,
  deletingContactFailed: false,

  contactsForNote: [],
  disputeCodes: [],
  contactList: [],

  showNotDataForReportDialog: false,
  isAttachmentLoaded: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const activitiesReducer = (state = initialState, action) => {
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (action.type) {
    case actionTypes.FETCHING_ACTIVITIES:
      return {
        ...state,
        fetchingActivities: true,
        fetchingActivitiesFailed: false,
        loading: true,
      };
    case actionTypes.FETCHED_ACTIVITIES:
      return {
        ...state,
        fetchingActivities: false,
        fetchingActivitiesFailed: false,
        fetchedActivities: true,
        activities: action.activities,
        loading: false,
        count: action.count,
        nextLink: action.nextLink,
        activitiesSkip: action.activitiesSkip,
      };
    case actionTypes.FETCHING_ACTIVITIES_FAILED:
      return {
        ...state,
        fetchingActivities: false,
        fetchingActivitiesFailed: true,
        fetchedActivities: true,
        loading: false,
      };
    case actionTypes.SET_ACTIVITIES_SKIP:
      return {
        ...state,
        activitiesSkip: action.activitiesSkip,
      };
    case actionTypes.FETCHED_ACTIVITY_INFO: {
      return {
        ...state,
        activityInfo: action.activityInfo,
        activityLoading: false,
      };
    }
    case actionTypes.FETCHING_ACTIVITY_INFO: {
      return {
        ...state,
        activityInfo: initialState.activityInfo,
        activityLoading: true,
      };
    }
    case actionTypes.FETCHING_ACTIVITY_INFO_FAILED: {
      return {
        ...state,
        activityInfo: initialState.activityInfo,
        activityLoading: false,
      };
    }
    case actionTypes.FETCHED_ACTIVITY_REPORT_INFO: {
      return {
        ...state,
        activityReportInfo: action.activityReportInfo,
      };
    }
    case actionTypes.FETCHING_ACTIVITY_REPORT_INFO:
    case actionTypes.FETCHING_ACTIVITY_REPORT_INFO_FAILED: {
      return {
        ...state,
        activityReportInfo: [],
      };
    }
    case actionTypes.FETCHED_ACTIVITY_TYPE_LIST: {
      return {
        ...state,
        activityTypeList: action.activityTypeList,
      };
    }
    case actionTypes.FETCHING_ACTIVITY_TYPE_LIST:
    case actionTypes.FETCHING_ACTIVITY_TYPE_LIST_FAILED: {
      return {
        ...state,
        activityTypeList: [],
      };
    }
    case actionTypes.FETCHED_ACTIVITY_TRANSACTIONS: {
      return {
        ...state,
        activityTransactions: action.activityTransactions,
        transactionCount: action.transactionCount,
        transactionNextLink: action.nextLink,
        loading: false,
      };
    }
    case actionTypes.FETCHING_ACTIVITY_TRANSACTIONS_FAILED: {
      return {
        ...state,
        loading: false,
        transactionCount: state.transactionCount,
        activityTransactions: [],
      };
    }
    case actionTypes.UPDATED_COMMUNICATION_TRANSACTION_SELECTED_ROWS:
      return {
        ...state,
        transactionSelectedRows: action.transactionSelectedRows,
      };
    case actionTypes.FETCHING_TEMPLATE_DROPDOWN_BY_TYPE:
    case actionTypes.ADDING_CONTACT_TO_NOTE:
    case actionTypes.ATTACHING_REPORT:
    case actionTypes.DELETING_FILES:
    case actionTypes.FETCHING_DISPUTE_CODES:
    case actionTypes.UPDATING_ALL_EDITED_TAGS:
    case actionTypes.FETCHING_ACTIVITY_TRANSACTIONS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATING_ALL_EDITED_TAGS_FAILED:
    case actionTypes.UPDATED_ALL_EDITED_TAGS:
    case actionTypes.DELETING_FILES_FAILED:
    case actionTypes.ATTACHING_REPORT_FAILED:
    case actionTypes.ATTACHED_REPORT: {
      return {
        ...state,
        loading: false,
      };
    }
    case actionTypes.FETCHED_TEMPLATE_DROPDOWN_BY_TYPE: {
      return {
        ...state,
        templateDropdown: action.list,
      };
    }
    case actionTypes.FETCHING_TEMPLATE_DROPDOWN_BY_TYPE_FAILED: {
      return {
        ...state,
        templateDropdown: [],
      };
    }
    case actionTypes.FETCHING_ACTIVITIES_CONTACTS: {
      return {
        ...state,
        fetchingActivitiesContactsList: true,
        fetchingActivitiesContactsListFailed: false,
        fetchedActivitiesContactsList: false,
      };
    }
    case actionTypes.FETCHED_ACTIVITIES_CONTACTS: {
      return {
        ...state,
        fetchingActivitiesContactsList: false,
        fetchingActivitiesContactsListFailed: false,
        fetchedActivitiesContactsList: true,
        contactsList: action.contactsList,
        personalContactList: action.personalContactList,
      };
    }
    case actionTypes.FETCHING_ACTIVITIES_CONTACTS_FAILED: {
      return {
        ...state,
        fetchingActivitiesContactsList: false,
        fetchingActivitiesContactsListFailed: true,
        fetchedActivitiesContactsList: false,
      };
    }
    case actionTypes.ADDING_CONTACT: {
      return {
        ...state,
        addingContact: true,
        addedContact: false,
        addedPersonalContact: false,
        addingContactFailed: false,
      };
    }
    case actionTypes.ADDING_CONTACT_FAILED: {
      return {
        ...state,
        addingContact: false,
        addedContact: action.addedContact,
        addedPersonalContact: action.addedPersonalContact,
        addingContactFailed: true,
      };
    }
    case actionTypes.ADDED_CONTACT: {
      return {
        ...state,
        addingContact: false,
        addedContact: action.addedContact,
        addedPersonalContact: action.addedPersonalContact,
        addingContactFailed: false,
      };
    }
    case actionTypes.FETCHING_CONTACTS: {
      return {
        ...state,
        fetchingContacts: true,
        fetchedContacts: false,
        fetchingContactsFailed: false,
      };
    }
    case actionTypes.FETCHED_CONTACTS: {
      return {
        ...state,
        fetchingContacts: false,
        fetchedContacts: true,
        fetchingContactsFailed: false,
        contacts: action.contacts,
        contactsCount: action.contactsCount,
      };
    }
    case actionTypes.FETCHING_CONTACTS_FAILED: {
      return {
        ...state,
        fetchingContacts: false,
        fetchedContacts: false,
        fetchingContactsFailed: true,
      };
    }

    case actionTypes.UPDATING_CONTACTS_PAGE_SIZE: {
      return {
        ...state,
        fetchingContacts: true,
      };
    }
    case actionTypes.UPDATING_CONTACT: {
      return {
        ...state,
        updatingContact: true,
        updatedContact: false,
        updatingContactFailed: false,
      };
    }
    case actionTypes.UPDATED_CONTACT: {
      return {
        ...state,
        updatingContact: false,
        updatedContact: true,
        updatingContactFailed: false,
      };
    }
    case actionTypes.UPDATING_CONTACT_FAILED: {
      return {
        ...state,
        updatingContact: false,
        updatedContact: false,
        updatingContactFailed: true,
      };
    }
    case actionTypes.DELETING_CONTACT: {
      return {
        ...state,
        deletingContact: true,
        deletedContact: false,
        deletingContactFailed: false,
      };
    }
    case actionTypes.DELETED_CONTACT: {
      return {
        ...state,
        deletingContact: false,
        deletedContact: true,
        deletingContactFailed: false,
      };
    }
    case actionTypes.DELETING_CONTACT_FAILED: {
      return {
        ...state,
        deletingContact: false,
        deletedContact: false,
        deletingContactFailed: true,
      };
    }

    case actionTypes.ADDED_CONTACT_TO_NOTE: {
      return {
        ...state,
        loading: false,
        contactsForNote: action.contactsForNote,
      };
    }
    case actionTypes.FETCHED_ACTIVITY_ATTACHMENTS: {
      return {
        ...state,
        loading: false,
        activityAttachments: action.activityAttachments,
        isAttachmentLoaded: false,
      };
    }
    case actionTypes.ATTACHING_TAGGED_INVOICE:
    case actionTypes.FETCHING_ACTIVITY_ATTACHMENTS:
      return {
        ...state,
        loading: true,
        isAttachmentLoaded: false,
      };
    case actionTypes.FETCHING_ACTIVITY_ATTACHMENTS_FAILED: {
      return {
        ...state,
        loading: false,
        activityAttachments: [],
        isAttachmentLoaded: false,
      };
    }

    case actionTypes.ATTACHING_TAGGED_INVOICE_FAILED:
      return {
        ...state,
        loading: false,
        isAttachmentLoaded: false,
        activityAttachments: [],
      };
    case actionTypes.ATTACHED_TAGGED_INVOICE: {
      return {
        ...state,
        loading: false,
        isAttachmentLoaded: true,
        activityAttachments: [...action.data],
      };
    }
    case actionTypes.DELETED_FILES: {
      return {
        ...state,
        loading: false,
        isAttachmentLoaded: false,
        activityAttachments: [...action.data],
      };
    }
    case actionTypes.FETCHED_DISPUTE_CODES: {
      return {
        ...state,
        loading: false,
        disputeCodes: action.data,
      };
    }
    case actionTypes.FETCHING_DISPUTE_CODES_FAILED: {
      return {
        ...state,
        loading: false,
        disputeCodes: [],
      };
    }
    case actionTypes.UPDATING_CHECK_UNCHECK_ATTACHMENT:
    case actionTypes.UPDATING_CHECK_UNCHECK_ATTACHMENT_FAILED: {
      const updatedList = [...state.activityAttachments];
      updatedList[action.data].excludeFromEmail =
        !updatedList[action.data].excludeFromEmail;
      return {
        ...state,
        activityAttachments: updatedList,
      };
    }
    case actionTypes.FETCHED_ALL_ACTIVE_CONTACTS: {
      return {...state, contactList: action.contacts};
    }
    case actionTypes.UPDATE_SHOW_NO_DATA_FOR_REPORT: {
      return {
        ...state,
        showNotDataForReportDialog: action.showNotDataForReportDialog,
      };
    }
    default:
      return state;
  }
};
export default activitiesReducer;
