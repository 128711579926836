import {ChangeEvent, forwardRef} from 'react';

import {
  FormControl,
  TextField as MuiTextField,
  TextFieldProps,
} from '@mui/material';

type Props = {
  /**
   * text field value
   */
  value?: string;
  /**
   * onChange event
   */
  fullWidth?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
} & TextFieldProps;

const TextField = forwardRef<HTMLInputElement, Props>(
  (
    {
      value,
      onChange,
      size = 'small',
      margin = 'normal',
      fullWidth = true,
      ...otherProps
    },
    ref,
  ) => {
    return (
      <FormControl margin={margin} fullWidth={fullWidth}>
        <MuiTextField
          value={value}
          onChange={onChange}
          autoComplete="off"
          size={size}
          inputRef={ref}
          {...otherProps}
        />
      </FormControl>
    );
  },
);

export default TextField;
