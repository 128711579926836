import {constants} from '../../constants/common';
import {actionTypes} from '../constants/actionTypes';

const initialState = {
  Dialog: {
    title: null,
    width: '',
    isDialogOpen: false,
    dialogContent: null,
    className: '',
    headerIcon: '',
    position: 'center',
  },
  currentRoute: '',
  pageRowCount: 10,
  loadedPage: 1,
  pageInputTooltip: constants.PRESS_ENTER_KEY_TO_GO_TO_THIS_PAGE,
  paginationCurrentPage: 1,
  bestFit: false,
  selectedRows: [],
  tableFilterDisplayType: 'menu',
  filteredData: [],
  enableEnhancedAdmin: null,
  sideNavRefresh: false,
  sidebarMenu: [],
  fetchingSideBarMenu: false,
  fetchedSideBarMenu: false,
  fetchingSideBarMenuFailed: false,
  selectedSideNavElement: {},
  addingToFavorite: false,
  addedToFavorite: false,
  addingToFavoriteFailed: false,
  selectedCustomer: null,
  showCustomerInfo: false,
  viewMessages: {
    count: 0,
    pageSize: 10,
    skip: 0,
    messages: [],
    fetchingMessages: false,
    fetchedMessages: false,
    fetchingMessagesFailed: false,
    exportingMessages: false,
    exportedMessages: false,
    exportingMessagesFailed: false,
  },
  jwtTokenData: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const generalReducer = (state = initialState, action) => {
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (action.type) {
    case actionTypes.SHOW_DIALOG:
      return {
        ...state,
        Dialog: {
          title: action.title,
          width: action.width,
          isDialogOpen: true,
          dialogContent: action.dialogContent,
          className: action.className,
          headerIcon: action.headerIcon || initialState.Dialog.headerIcon,
          position: action.position || initialState.Dialog.position,
        },
      };
    case actionTypes.HIDE_DIALOG:
      return {...state, Dialog: initialState.Dialog};
    case actionTypes.SETTING_CURRENT_ROUTE:
      return {...state, currentRoute: action.route};
    case actionTypes.UPDATE_PAGE_ROW_COUNT:
      return {...state, pageRowCount: action.pageRowCount};
    case actionTypes.SET_LOADED_PAGE:
      return {...state, loadedPage: action.loadedPage};
    case actionTypes.SET_PAGE_INPUT_TOOLTIP:
      return {...state, pageInputTooltip: action.pageInputTooltip};
    case actionTypes.SET_CURRENT_PAGE:
      return {...state, paginationCurrentPage: action.paginationCurrentPage};
    case actionTypes.SET_BEST_FIT:
      return {...state, bestFit: state.bestFit !== true};
    case actionTypes.SET_SELECTED_ROWS:
      return {...state, selectedRows: action.rows};
    case actionTypes.SET_TABLE_FILTER_DISPLAY_TYPE:
      return {...state, tableFilterDisplayType: action.tableFilterDisplayType};
    case actionTypes.SET_FILTERED_DATA:
      return {...state, filteredData: action.filteredData};
    case actionTypes.ENABLE_ENHANCED_ADMIN: {
      return {...state, enableEnhancedAdmin: action.enableEnhancedAdmin};
    }
    case actionTypes.REFRESH_SIDE_NAV: {
      return {
        ...state,
        sideNavRefresh: action.sideNavRefresh,
      };
    }
    case actionTypes.FETCHING_SIDEBAR_MENU: {
      return {
        ...state,
        fetchingSideBarMenu: true,
        fetchedSideBarMenu: false,
        fetchingSideBarMenuFailed: false,
      };
    }
    case actionTypes.FETCHED_SIDEBAR_MENU: {
      return {
        ...state,
        fetchingSideBarMenu: false,
        fetchedSideBarMenu: true,
        fetchingSideBarMenuFailed: false,
        sidebarMenu: action.sidebarMenu,
      };
    }
    case actionTypes.FETCHING_SIDEBAR_MENU_FAILED: {
      return {
        ...state,
        fetchingSideBarMenu: false,
        fetchedSideBarMenu: false,
        fetchingSideBarMenuFailed: true,
        sidebarMenu: initialState.sidebarMenu,
      };
    }
    case actionTypes.UPDATE_SELECTED_SIDENAV_ELEMENT: {
      return {
        ...state,
        selectedSideNavElement: action.selectedSideNavElement,
      };
    }

    case actionTypes.ADDING_TO_FAVORITE: {
      return {
        ...state,
        addingToFavorite: true,
        addedToFavorite: false,
        addingToFavoriteFailed: false,
      };
    }
    case actionTypes.ADDED_TO_FAVORITE: {
      return {
        ...state,
        addingToFavorite: false,
        addedToFavorite: true,
        addingToFavoriteFailed: false,
      };
    }
    case actionTypes.ADDING_TO_FAVORITE_FAILED: {
      return {
        ...state,
        addingToFavorite: false,
        addedToFavorite: false,
        addingToFavoriteFailed: true,
      };
    }
    case actionTypes.UPDATE_SELECTED_CUSTOMER: {
      return {
        ...state,
        selectedCustomer: action.selectedCustomer,
      };
    }
    case actionTypes.SHOW_CUSTOMER_INFO_CONTAINER: {
      return {
        ...state,
        showCustomerInfo: action.showCustomerInfo,
      };
    }
    case actionTypes.FETCHING_MESSAGES: {
      return {
        ...state,
        viewMessages: {
          ...state.viewMessages,
          fetchingMessages: true,
          fetchedMessages: false,
          fetchingMessagesFailed: false,
        },
      };
    }
    case actionTypes.FETCHED_MESSAGES: {
      return {
        ...state,
        viewMessages: {
          ...state.viewMessages,
          messages: action.messages,
          count: action.count,
          fetchingMessages: false,
          fetchedMessages: true,
          fetchingMessagesFailed: false,
        },
      };
    }
    case actionTypes.FETCHING_MESSAGES_FAILED: {
      return {
        ...state,
        viewMessages: {
          ...state.viewMessages,
          fetchingMessages: false,
          fetchedMessages: false,
          fetchingMessagesFailed: true,
        },
      };
    }
    case actionTypes.UPDATE_VIEW_MESSAGES_PAGE_SIZE: {
      return {
        ...state,
        viewMessages: {
          ...state.viewMessages,
          pageSize: action.pageSize,
        },
      };
    }
    case actionTypes.UPDATE_VIEW_MESSAGES_SKIP: {
      return {
        ...state,
        viewMessages: {
          ...state.viewMessages,
          skip: action.skip,
        },
      };
    }

    case actionTypes.EXPORTING_MESSAGES: {
      return {
        ...state,
        viewMessages: {
          ...state.viewMessages,
          fetchingMessages: true,
          exportingMessages: true,
          exportedMessages: false,
          exportingMessagesFailed: false,
        },
      };
    }
    case actionTypes.EXPORTED_MESSAGES: {
      return {
        ...state,
        viewMessages: {
          ...state.viewMessages,
          fetchingMessages: false,
          exportingMessages: false,
          exportedMessages: true,
          exportingMessagesFailed: false,
        },
      };
    }
    case actionTypes.EXPORTING_MESSAGES_FAILED: {
      return {
        ...state,
        viewMessages: {
          ...state.viewMessages,
          fetchingMessages: false,
          exportingMessages: false,
          exportedMessages: false,
          exportingMessagesFailed: true,
        },
      };
    }
    case actionTypes.FETCHING_JWT_TOKENS: {
      return {
        ...state,
        jwtTokenData: '',
      };
    }
    case actionTypes.FETCHED_JWT_TOKENS: {
      return {
        ...state,
        jwtTokenData: action.jwtTokenData,
      };
    }
    case actionTypes.FETCHING_JWT_TOKENS_FAILED: {
      return {
        ...state,
        jwtTokenData: null,
      };
    }
    default:
      return state;
  }
};
export default generalReducer;
