import {GridLogicOperator} from '@mui/x-data-grid';
import {actionTypes} from '../constants/actionTypes';

const initialState = {
  communicationsSkip: 0,
  communications: [],
  viewSettings: null,
  fetchingCommunicationsFailed: false,
  fetchedCommunications: false,
  communicationsLoading: false,
  communicationsCount: 0,
  communicationsNextLink: '',
  isInquiriesActivitiesPage: false,
  isShowingArchivedActivities: false,
  isShowingAllHighlightedActivities: false,
  fetchingActivitiesForHighlightedRow: false,
  fetchedActivitiesForHighlightedRow: false,
  fetchingActivitiesForHighlightedRowFailed: false,
  communicationsSelectedRows: [],
  communicationsUnselectedRows: [],
  communicationsBulkUpdateStarted: false,
  communicationsBulkUpdateCompleted: false,
  communicationsBulkUpdateFailed: false,
  exportingToExcel: false,
  exportedToExcel: false,
  exportingToExcelFailed: false,
  isMailNotRead: true,
  isShowArchived: false,
  isShowActivitiesForHighlightedRow: false,
  settingId: null,
  alertDescription: '',
  filterModel: {logicOperator: GridLogicOperator.And, items: []},
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const communicationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_COMMUNICATIONS:
      return {
        ...state,
        fetchingCommunicationsFailed: false,
        communicationsLoading: true,
      };
    case actionTypes.FETCHED_COMMUNICATIONS:
      return {
        ...state,
        fetchingCommunicationsFailed: false,
        fetchedCommunications: true,
        communications: action.communications,
        communicationsLoading: false,
        communicationsCount: action.communicationsCount,
        communicationsNextLink: action.communicationsNextLink,
        communicationsSkip: action.communicationsSkip,
      };
    case actionTypes.FETCHING_COMMUNICATIONS_FAILED:
      return {
        ...state,
        fetchingCommunicationsFailed: true,
        fetchedCommunications: true,
        communicationsLoading: false,
        communications: [],
        communicationsCount: 0,
      };
    case actionTypes.SET_INQUIRIES_ACTIVITIES_PAGE:
      return {
        ...state,
        isInquiriesActivitiesPage: action.isInquiriesActivitiesPage,
      };
    case actionTypes.SET_SHOW_ARCHIVED_ACTIVITIES:
      return {
        ...state,
        isShowingArchivedActivities: action.isShowingArchivedActivities,
      };
    case actionTypes.SET_SHOW_ALL_HIGHLIGHTED_ACTIVITIES:
      return {
        ...state,
        isShowingAllHighlightedActivities:
          action.isShowingAllHighlightedActivities,
      };
    case actionTypes.FETCHING_ACTIVITIES_FOR_HIGHLIGHTED_ROW:
    case actionTypes.FETCHING_ACTIVITIES_FOR_HIGHLIGHTED_ROW_FAILED:
      return {
        ...state,
        fetchingActivitiesForHighlightedRow: action.value,
      };
    case actionTypes.FETCHED_ACTIVITIES_FOR_HIGHLIGHTED_ROW:
      return {
        ...state,
        fetchingActivitiesForHighlightedRow: action.value,
        communications: action.communications,
        communicationsLoading: false,
      };
    case actionTypes.UPDATING_COMMUNICATIONS_SELECTED_ROWS:
      return {
        ...state,
        communicationsLoading: true,
      };
    case actionTypes.UPDATED_COMMUNICATIONS_SELECTED_ROWS:
      return {
        ...state,
        communicationsSelectedRows: action.communicationsSelectedRows,
      };
    case actionTypes.COMMUNICATIONS_BULK_UPDATE_STARTED:
      return {
        ...state,
        communicationsLoading: true,
        communicationsBulkUpdateStarted: true,
        communicationsBulkUpdateCompleted: false,
        communicationsBulkUpdateFailed: false,
      };
    case actionTypes.COMMUNICATIONS_BULK_UPDATE_COMPLETED:
      return {
        ...state,
        communicationsLoading: false,
        communicationsBulkUpdateStarted: false,
        communicationsBulkUpdateCompleted: true,
        communicationsBulkUpdateFailed: false,
      };
    case actionTypes.COMMUNICATIONS_BULK_UPDATE_FAILED:
      return {
        ...state,
        communicationsLoading: false,
        communicationsBulkUpdateStarted: false,
        communicationsBulkUpdateCompleted: false,
        communicationsBulkUpdateFailed: true,
      };

    case actionTypes.EXPORTING_COMMUNICATIONS_TO_EXCEL:
      return {
        ...state,
        communicationsLoading: true,
        exportingToExcel: true,
        exportedToExcel: false,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTED_COMMUNICATIONS_TO_EXCEL:
      return {
        ...state,
        communicationsLoading: false,
        exportingToExcel: false,
        exportedToExcel: true,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTING_COMMUNICATIONS_TO_EXCEL_FAILED:
      return {
        ...state,
        communicationsLoading: false,
        exportingToExcel: false,
        exportedToExcel: false,
        exportingToExcelFailed: true,
      };
    case actionTypes.SET_IS_MAIL_NOT_READ:
      return {...state, isMailNotRead: action.isMailNotRead};
    case actionTypes.SET_IS_SHOW_HIGHLIGHTED_ACTIVITIES:
      return {
        ...state,
        isShowActivitiesForHighlightedRow:
          action.isShowActivitiesForHighlightedRow,
      };
    case actionTypes.SET_VIEW_SETTING_KEY:
      return {
        ...state,
        settingId: action.settingId,
      };

    case actionTypes.SET_VIEW_SETTING_OBJECT: {
      return {
        ...state,
        viewSettings: {
          ...action.payload.viewSettings,
          columnSetting: action.payload.viewSettings.columnSetting,
          sortSetting: null,
        },
        settingId: action.payload.settingId,
      };
    }

    case actionTypes.CLEAN_UP_COMMUNICATION: {
      return {
        ...initialState,
      };
    }

    case actionTypes.SET_IS_SHOW_MAIL_ARCHIVED:
      return {...state, isShowArchived: action.isShowArchived};

    case actionTypes.SET_ALERT_DESCRIPTION:
      return {...state, alertDescription: action.alertDescription};

    case actionTypes.SET_COMMUNICATION_LOADING:
      return {...state, communicationsLoading: action.communicationLoading};

    default:
      return state;
  }
};
export default communicationsReducer;
