import {constants} from '../../constants/common';
import {exportActivitiesOverview} from '../../services/activities';
import {fetchAccountOverviewActivities} from '../../services/communications';
import {downloadExcel} from '../../utils/fileUtils';
import {stripHTML} from '../../utils/stringUtils';
import {actionTypes} from '../constants/actionTypes';

export const getAllActivities =
  (options, showSnackbar) => (dispatch, getState) => {
    const activitiesOverviewState = getState().activitiesOverviewReducer;
    const isEmailTemplateActivities = Boolean(options?.isEmailTemplate);

    dispatch({
      type: isEmailTemplateActivities
        ? actionTypes.FETCHING_EMAIL_TEMPLATE_ACTIVITIES
        : actionTypes.FETCHING_ACTIVITIES_OVERVIEW,
    });
    const filterQuery =
      options.settingKey === activitiesOverviewState?.viewSettings?.settingsKey
        ? options.filterQuery
        : '';
    const updatedOptions = {
      ...options,
      globalFilterQuery: filterQuery,
    };
    fetchAccountOverviewActivities(updatedOptions)
      .then(res => {
        if (res.data.value && res.data.value.length) {
          res.data.value.forEach(x => {
            // eslint-disable-next-line no-param-reassign
            x.notesText = stripHTML(x.notes);
          });
        }
        dispatch({
          type: isEmailTemplateActivities
            ? actionTypes.FETCHED_EMAIL_TEMPLATE_ACTIVITIES
            : actionTypes.FETCHED_ACTIVITIES_OVERVIEW,
          ...(isEmailTemplateActivities
            ? {
                emailTemplateActivities: res.data.value,
                emailTemplateActivitiesCount: res.data.count,
              }
            : {
                activitiesOverview: res.data.value,
                activitiesOverviewCount: res.data.count,
                activitiesOverviewNextLink: res.data.nextLink,
                viewSettings: res.data.viewSetting,
              }),
        });
        return res.data;
      })
      .catch(() => {
        dispatch({
          type: isEmailTemplateActivities
            ? actionTypes.FETCHING_EMAIL_TEMPLATE_ACTIVITIES_FAILED
            : actionTypes.FETCHING_ACTIVITIES_OVERVIEW_FAILED,
        });
        showSnackbar('Failed to fetch activities', constants.ERROR, 'error');
      });
  };

export const updateActivitiesOverviewSkip = activitiesOverviewSkip => {
  return {
    type: actionTypes.UPDATE_ACTIVITIES_OVERVIEW_SKIP,
    activitiesOverviewSkip,
  };
};

export const updateActivitiesOverviewAllRowsSelected =
  selectedRows => dispatch => {
    dispatch({
      type: actionTypes.UPDATED_ACTIVITIES_OVERVIEW_SELECTED_ROWS,
      activitiesOverviewSelectedRows: selectedRows,
    });
  };

export const updateActivitiesOverviewPageSize =
  activitiesOverviewPageSize => dispatch => {
    dispatch({type: actionTypes.UPDATING_ACTIVITIES_OVERVIEW_PAGE_SIZE});
    dispatch({
      type: actionTypes.UPDATE_ACTIVITIES_OVERVIEW_PAGE_SIZE,
      activitiesOverviewPageSize,
    });
  };

export const activityOverviewExportToExcel =
  (options, showSnackbar) => (dispatch, getState) => {
    const activitiesOverviewState = getState().activitiesOverviewReducer;

    dispatch({type: actionTypes.EXPORTING_ACTIVITIES_TO_EXCEL});
    showSnackbar('Exporting activities to excel', constants.INFO, 'info');
    exportActivitiesOverview(activitiesOverviewState, options)
      .then(res => {
        downloadExcel(
          res.data,
          'activity',
          showSnackbar,
          constants.ACTIVITIES_EXPORTED_TO_EXCEL,
        );
        dispatch({type: actionTypes.EXPORTED_ACTIVITIES_TO_EXCEL});
      })
      .catch(() => {
        showSnackbar(
          'Failed to export activities to excel',
          constants.ERROR,
          'error',
        );
        dispatch({type: actionTypes.EXPORTING_ACTIVITIES_TO_EXCEL_FAILED});
      });
  };

export const applyActivitiesOverviewGlobalFilter =
  activitiesOverviewGlobalFilter => {
    return {
      type: actionTypes.SET_ACTIVITIES_OVERVIEW_GLOBAL_FILTER,
      activitiesOverviewGlobalFilter,
    };
  };

export const updateActivitiesOverviewSort = ({field, direction}) => {
  return {
    type: actionTypes.UPDATE_ACTIVITIES_OVERVIEW_SORT,
    sortBy: field,
    sortDirection: direction,
  };
};

export const updateIsCustomerDropdownValueChanged =
  isCustomerDropdownValueChanged => dispatch => {
    dispatch({
      type: actionTypes.UPDATE_CUSTOMER_DROPDOWN_VALUE_CHANGED,
      isCustomerDropdownValueChanged,
    });
  };

export const updateActivitiesOverviewLoadedPage =
  activitiesOverviewLoadedPage => dispatch => {
    dispatch({
      type: actionTypes.UPDATE_ACTIVITIES_OVERVIEW_LOADED_PAGE,
      activitiesOverviewLoadedPage,
    });
  };

export const updateActivitiesOverviewCurrentPage =
  activitiesOverviewCurrentPage => dispatch => {
    dispatch({
      type: actionTypes.UPDATE_ACTIVITIES_OVERVIEW_CURRENT_PAGE,
      activitiesOverviewCurrentPage,
    });
  };

export const updateIsEmailTemplateActivities =
  isEmailTemplateActivities => dispatch => {
    dispatch({
      type: actionTypes.IS_EMAIL_TEMPLATE_ACTIVITIES,
      isEmailTemplateActivities,
    });
  };
export const updateAccountActivityViewSetting = settingId => {
  return {
    type: actionTypes.UPDATE_ACCOUNT_ACTIVITIES_SETTING_KEY,
    settingId,
  };
};

export const updateAccountActivityViewSettingObject = ({
  settingId,
  viewSettings,
}) => {
  return {
    type: actionTypes.UPDATE_ACCOUNT_ACTIVITIES_SETTING_OBJECT,
    payload: {settingId, viewSettings},
  };
};

export const cleanUpActivitiesOverviewData = () => {
  return {
    type: actionTypes.CLEAN_UP_ACTIVITIES_OVERVIEW_DATA,
  };
};
