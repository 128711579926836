import {baseApi} from './baseApi';

interface Collector {
  managingUser: string;
  userID: string;
  friendlyName: string;
  userType: number;
  active: number;
  id: string;
  tableName: string;
  idName: string;
}
interface Company {
  groupKey: string;
  displayCompanyID: string;
  companyID: string;
  id: string;
  tableName: string;
  idName: string;
}

interface Currency {
  groupKey: string;
  dashBoardCurrID: string;
  companyID: string;
  id: string;
  tableName: string;
  idName: string;
}

interface GraphBasePayload {
  accountKey: string;
  userId: string;
  dashboardCurrId: string;
  companyId: string;
}

interface TeamUserActivitiesPayload extends GraphBasePayload {
  startDate?: Date | undefined | string;
  endDate?: Date | undefined | string | null;
}

export interface CashReceipt {
  summaryType: string;
  accountKey: string;
  userId: string;
  companyID: string;
  graphDate: string;
  dashboardCurrID: string;
  promised: number;
  expected: number;
  predicted: number;
  due: number;
  unresolved: number;
  actual: number;
  unApplied: number;
  disputed: number;
  credits: number;
  projected: number;
  atRisk: number;
  graphProjected: number;
  id: string;
  tableName: string;
  idName: string;
}

export interface AgingInfo {
  ageDays1: number;
  ageDays2: number;
  ageDays3: number;
  ageDays4: number;
  calcDaysToPay1: number;
  calcDaysToPay2: number;
  calcDaysToPay3: number;
  calcDaysToPay4: number;
  calcDaysToPay5: number;
  calcDaysToPay6: number;
  targetDSO1Mths: number;
  targetDSO2Mths: number;
  targetDSO3Mths: number;
  targetDSO4Mths: number;
  targetDSO5Mths: number;
  targetDSO6Mths: number;
  lateAgeDays1: number;
  lateAgeDays2: number;
  lateAgeDays3: number;
  lateAgeDays4: number;
  accountKey: string;
  userId: string;
  companyId: string;
  summaryType: string;
  dashboardCurrId: string;
  yearMonthPrior1: string;
  yearMonthPrior2: string;
  yearMonthPrior3: string;
  yearMonthPrior4: string;
  yearMonthPrior5: string;
  yearMonthPrior6: string;
  ageCurrent: number;
  age1: number;
  age2: number;
  age3: number;
  age4: number;
  totalAge: number;
  cocBalance: number;
  projInDispute1: number;
  projInDispute2: number;
  projInDispute3: number;
  projInDispute4: number;
  projInDispute5: number;
  projInDisputePast: number;
  projUnappliedPmt: number;
  projCreditMemo: number;
  lateAge1: number;
  lateAge2: number;
  lateAge3: number;
  lateAge4: number;
  lateAgeCurrent: number;
  lateAgeFuture: number;
  projPmts1: number;
  projPmts2: number;
  projPmts3: number;
  projPmts4: number;
  projPmts5: number;
  projPmtsPast: number;
  avgDays1: number;
  avgDays2: number;
  avgDays3: number;
  avgDays4: number;
  avgDays5: number;
  avgDays6: number;
  avgDays7: number;
  avgDaysTotal: number;
  daysPayPrior1: number;
  daysPayPrior2: number;
  daysPayPrior3: number;
  daysPayPrior4: number;
  daysPayPrior5: number;
  daysPayPrior6: number;
  daysPayLatePrior1: number;
  daysPayLatePrior2: number;
  daysPayLatePrior3: number;
  daysPayLatePrior4: number;
  daysPayLatePrior5: number;
  daysPayLatePrior6: number;
  daysLateWgtPrior1: number;
  daysLateWgtPrior2: number;
  daysLateWgtPrior3: number;
  daysLateWgtPrior4: number;
  daysLateWgtPrior5: number;
  daysLateWgtPrior6: number;
  avgDaysLate1: number;
  avgDaysLate2: number;
  avgDaysLate3: number;
  avgDaysLate4: number;
  avgDaysLate5: number;
  avgDaysLate6: number;
  avgDaysLate7: number;
  avgDaysLateTotal: number;
  dsO6Label: string;
  dsO5Label: string;
  dsO4Label: string;
  dsO3Label: string;
  dsO2Label: string;
  dsO1Label: string;
  lastSyncEndDateTime: string;
  dsO1Mths: number;
  dsO2Mths: number;
  dsO3Mths: number;
  dsO4Mths: number;
  dsO5Mths: number;
  dsO6Mths: number;
  id: string;
  tableName: string;
  idName: string;
}

export interface TopCustomerInfo {
  accountKey: string;
  userId: string;
  companyId: string;
  custKey: number;
  custName: string;
  dashboardCurrId: string;
  totalAge: number;
  ageCurrent: number;
  over1: number;
  over2: number;
  over3: number;
  over4: number;
  lateOverCurrent: number;
  lateOver1: number;
  lateOver2: number;
  lateOver3: number;
  lateOver4: number;
  id: string;
  tableName: string;
  idName: string;
}

export interface UserActivity {
  accountKey: string;
  userId: string;
  companyId: string;
  summaryType: number;
  activityId: string;
  activityKey: number;
  thisCountCreatedManual: number;
  lastCountCreatedManual: number;
  thisCountCompletedManual: number;
  lastCountCompletedManual: number;
  thisCountCreatedAuto: number;
  lastCountCreatedAuto: number;
  thisCountCompletedAuto: number;
  lastCountCompletedAuto: number;
  thisCountCreatedBoth: number;
  thisCountCompletedBoth: number;
  lastCountCreatedBoth: number;
  lastCountCompletedBoth: number;
  id: string;
  tableName: string;
  idName: string;
}

export interface PortalUsageCounts {
  accountKey: string;
  uniqueHitsToday: number;
  uniqueHitsTodayChange: number;
  customersToday: number;
  customersTodayChange: number;
  uniqueHitsThisWeek: number;
  uniqueHitsThisWeekChange: number;
  uniqueCustomersThisWeek: number;
  uniqueCustomersThisWeekChange: number;
}

export interface PortalPaymentTotals {
  accountKey: string;
  userId: string;
  companyId: string;
  dashboardCurrId: string;
  paymentsToday?: number;
  paymentsTodayChange?: number;
  collectedToday?: number;
  collectedTodayChange?: number;
  paymentsThisWeek?: number;
  paymentsThisWeekChange?: number;
  collectedThisWeek?: number;
  collectedThisWeekChange?: number;
}

export interface TeamActivitiesSummary {
  userName: string;
  mgdUserId: string;
  accountKey: string;
  userId: string;
  dateEntered: Date;
  dashboardCurrID: string;
  companyID: string;
  accountsOwned?: number;
  openInvoicesCount?: number;
  accountsTouched?: number;
  invoicesTouched?: number;
  callsMade?: number;
  emailsSent?: number;
  totalCollected?: number;
  totalAging?: number;
  avgResponseTime?: number;
  noReplyCount?: number;
  index?: number;
}

export const dashboardApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getDashboardCollectorList: builder.query<Collector[], {userId: string}>({
      query: ({userId}) => `/v4/api/dashboard/collectors/for/${userId}`,
    }),
    getDashboardCompanyList: builder.query<Company[], {groupKey: string}>({
      query: ({groupKey}) => `/v4/api/dashboard/companies/for/${groupKey}`,
    }),
    getDashboardCurrencyForCompany: builder.query<
      Currency[],
      {companyId: string; groupKey: string}
    >({
      query: ({companyId, groupKey}) =>
        `/v4/api/dashboard/currencies/for/${groupKey}/${companyId}`,
    }),
    getDashboardCashReceipts: builder.query<
      CashReceipt[],
      {summaryType: 'weekly' | 'monthly'; payload: GraphBasePayload}
    >({
      query: ({summaryType, payload}) => ({
        url: `/v4/api/dashboard/cashReceipts/${summaryType}`,
        method: 'POST',
        body: payload,
      }),
    }),
    getDashboardAgingInfo: builder.query<
      AgingInfo[],
      {payload: GraphBasePayload}
    >({
      query: ({payload}) => ({
        url: `/v4/api/dashboard/agingInfo`,
        method: 'POST',
        body: payload,
      }),
    }),
    getTopCustomersInfo: builder.query<
      TopCustomerInfo[],
      {payload: GraphBasePayload}
    >({
      query: ({payload}) => ({
        url: `/v4/api/dashboard/topCustomerInfo`,
        method: 'POST',
        body: payload,
      }),
    }),
    getUserDashboardActivities: builder.query<
      UserActivity[],
      {payload: GraphBasePayload; summaryType: number}
    >({
      query: ({payload, summaryType}) => ({
        url: `/v4/api/dashboard/useractivities/${summaryType}`,
        method: 'POST',
        body: payload,
      }),
    }),
    getPortalUsageCounts: builder.query<
      PortalUsageCounts[],
      {accountKey: string}
    >({
      query: ({accountKey}) => ({
        url: `/v4/api/dashboard/portalUsage/${accountKey}`,
        method: 'GET',
      }),
    }),
    getPortalPaymentsData: builder.query<
      PortalPaymentTotals[],
      {payload: GraphBasePayload}
    >({
      query: ({payload}) => ({
        url: `/v4/api/dashboard/portalPaymentsInfo`,
        method: 'POST',
        body: payload,
      }),
    }),
    getTeamActivitiesSummary: builder.query<
      TeamActivitiesSummary[],
      {payload: TeamUserActivitiesPayload}
    >({
      query: ({payload}) => ({
        url: `/v4/api/dashboard/teamuseractivities`,
        method: 'POST',
        body: payload,
      }),
    }),
    refreshTeamActivitiesSummary: builder.query<
      {success: boolean},
      {
        groupKey: string;
        currId: string;
      }
    >({
      query: ({groupKey, currId}) => ({
        url: `/v4/api/dashboard/teamactivityRefresh/${groupKey}/${currId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetDashboardCollectorListQuery,
  useGetDashboardCompanyListQuery,
  useGetDashboardCurrencyForCompanyQuery,
  useGetDashboardCashReceiptsQuery,
  useGetDashboardAgingInfoQuery,
  useGetTopCustomersInfoQuery,
  useGetUserDashboardActivitiesQuery,
  useGetPortalUsageCountsQuery,
  useGetPortalPaymentsDataQuery,
  useGetTeamActivitiesSummaryQuery,
  useLazyRefreshTeamActivitiesSummaryQuery,
} = dashboardApi;
