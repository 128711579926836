const selectAllPlugin: any = {
  // @Required
  // plugin name
  name: 'selectAll',
  // @Required
  // data display
  display: 'command',
  command: 'select-all-plugin-command',

  // @Required
  // add function - It is called only once when the plugin is first run.
  add() {},
  action() {
    const editorEl = document.getElementsByClassName('sun-editor-editable')[0];
    if (editorEl) {
      (editorEl as HTMLElement).focus();
      this.execCommand('selectAll', false, null);
    }
    const range = document.createRange();
    range.selectNodeContents(editorEl);
    const sel = window.getSelection() ?? null;
    if (sel) {
      sel.removeAllRanges();
      sel.addRange(range);
    } else {
      console.error('Failed to select all content');
    }
  },
};

export default selectAllPlugin;
