import data from '../lib/data';

export const fetchManagedUsers = endpoint =>
  data
    .get(endpoint)
    .then(res => res.data)
    .catch(e => e);

export const fetchUsersByUserKey = ({groupKey}) => {
  const endpoint = `v1/api/user/for/${groupKey}`;
  return data.get(endpoint);
};

export const fetchUserByUserKey = ({userKey}) => {
  const endpoint = `v1/api/user/for/info/${userKey}`;
  return data.get(endpoint);
};

export const fetchUdfDetailsService = groupKey => {
  const endpoint = `v2/api/customerInfo/for/udfDetails/${groupKey}`;
  return data.get(endpoint);
};
