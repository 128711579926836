import {constants} from '../constants/common';

interface ICodeSnippetList {
  label: string;
  value: string;
}

export const snippetList: ICodeSnippetList[] = [
  {label: constants.INSERT_CODE_SNIPPET, value: ''},
  {
    label: constants.CUSTOMER_LOGO,
    value: '{Logo}',
  },
  {
    label: constants.CUSTOMER_STATEMENT_LINK,
    value: '{ @CustAccessStmtLink|View Statement }',
  },
  {
    label: constants.CUSTOMER_DOCUMENT_LINK,
    value: '{ @CustDocLink|View Documents }',
  },
  {
    label: constants.CUSTOMER_INVOICE_LINK,
    value: '{ @CustInvcLink|View Invoices }',
  },
  {
    label: constants.CUSTOMER_PAYMENTS_LINK,
    value: '{ @CustPmtLink|View Payments }',
  },
  {
    label: constants.CUSTOMER_CONTACTS_LINK,
    value: '{ @CustContactsLink|View Contacts }',
  },
  {
    label: constants.CUSTOMER_CONTACT_INFO_LINK,
    value: '{ @CustContactInfoLink|View Contact Info }',
  },
  {
    label: constants.CUSTOMER_STATEMENT_BUTTON,
    value: '{ @CustAccessStmtButton|View Statement }',
  },
  {
    label: constants.CUSTOMER_DOCUMENT_BUTTON,
    value: '{ @CustDocButton|View Documents }',
  },
  {
    label: constants.CUSTOMER_INVOICE_BUTTON,
    value: '{ @CustInvcButton|View Invoices }',
  },
  {
    label: constants.CUSTOMER_PAYMENTS_BUTTON,
    value: '{ @CustPmtButton|View Payments }',
  },
  {
    label: constants.CUSTOMER_CONTACTS_BUTTON,
    value: '{ @CustContactsButton|View Contacts }',
  },
  {
    label: constants.CUSTOMER_CONTACTINFO_BUTTON,
    value: '{ @CustContactInfoButton|View Contact Info }',
  },
  {label: constants.CUSTOMER, value: '{CustID}'},
  {label: constants.CUSTOMER_ACCOUNT_NUMBER, value: '{CustAcctNo}'},
  {label: constants.CUSTOMER_NAME, value: '{CustName|Valued Customer}'},
  {label: constants.PRIMARY_CONTACT_NAME, value: '{ContactName}'},
  {label: constants.TOTAL_AGING, value: '{TotalAge}'},
  {label: constants.TOTAL_PAST_DUE, value: '{LateOverCurrent}'},
  {label: constants.USERNAME, value: '{AssignedUserName}'},
  {label: constants.USER_EMAIL, value: '{AssignedUserEmail}'},
  {label: constants.USER_ADDRESS_1, value: '{AssignedUserAddr1}'},
  {label: constants.USER_ADDRESS_2, value: '{AssignedUserAddr2}'},
  {label: constants.USER_ADDRESS_3, value: '{AssignedUserAddr3}'},
  {label: constants.USER_ADDRESS_4, value: '{AssignedUserAddr4}'},
  {label: constants.USER_ADDRESS_5, value: '{AssignedUserAddr5}'},
  {label: constants.USER_CITY, value: '{AssignedUserCity}'},
  {label: constants.USER_STATE, value: '{AssignedUserState}'},
  {label: constants.USER_POSTAL_CODE, value: '{AssignedUserPostalCode}'},
  {label: constants.USER_COUNTRY, value: '{AssignedUserCountry}'},
  {label: constants.USER_PHONE, value: '{AssignedUserPhoneNum}'},
  {label: constants.USER_PHONE_EXT, value: '{AssignedUserPhoneExt}'},
  {label: constants.USER_FAX, value: '{AssignedUserFaxNum}'},
  {label: constants.USER_FAX_EXT, value: '{AssignedUserFaxExt}'},
  {label: constants.NA_AGE_CURRENT, value: '{NAAgeCurrent}'},
  {label: constants.NA_AGE_FUTURE, value: '{NAAgeFuture}'},
  {label: constants.NA_AGE_1, value: '{NAAge1}'},
  {label: constants.NA_AGE_2, value: '{NAAge2}'},
  {label: constants.NA_AGE_3, value: '{NAAge3}'},
  {label: constants.NA_AGE_4, value: '{NAAge4}'},
  {label: constants.NA_LATE_AGE_CURRENT, value: '{NALateAgeCurrent}'},
  {label: constants.NA_LATE_AGE_FUTURE, value: '{NALateAgeFuture}'},
  {label: constants.NA_LATE_AGE_1, value: '{NALateAge1}'},
  {label: constants.NA_LATE_AGE_2, value: '{NALateAge2}'},
  {label: constants.NA_LATE_AGE_3, value: '{NALateAge3}'},
  {label: constants.NA_LATE_AGE_4, value: '{NALateAge4}'},
  {label: constants.NA_TOTAL_AGE, value: '{NATotalAge}'},
  {label: constants.NA_CREDIT_LIMIT, value: '{NACreditLimit}'},
  {label: constants.NA_AVAILABLE_CREDIT, value: '{NAAvailableCredit}'},
  {label: constants.TRAN_ID, value: '{TranID}'},
  {label: constants.TRAN_NO, value: '{TranNo}'},
  {label: constants.TRAN_CURR_ID, value: '{TranCurrID}'},
  {label: constants.TAGGED_INVC_TOTAL, value: '{TaggedInvcTotal}'},
  {label: constants.TAGGED_HC_INVC_TOTAL, value: '{TaggedHCInvcTotal}'},
  {label: constants.TAGGED_INVC_BALANCE, value: '{TaggedInvcBalance}'},
  {label: constants.TAGGED_HD_INVC_BALANCE, value: '{TaggedHCInvcBalance}'},
];
