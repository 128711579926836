import {Core} from 'suneditor/src/lib/core';
import {snippetList} from '../../../../../utils/codeSnippets';

const snippetPlugin: any = {
  // @Required
  // plugin name
  name: 'selectSnippet',

  // @Required
  // data display
  display: 'submenu',
  command: 'snippet-plugin-command',

  // @Required
  // add function - It is called only once when the plugin is first run.
  // This function generates HTML to append and register the event.
  // arguments - (core : core object, targetElement : clicked button element)
  add(core: Core, targetElement: HTMLButtonElement | HTMLDivElement) {
    // Generate submenu HTML
    // Always bind "core" when calling a plugin function
    const listDiv = this.setSubmenu.call(core);

    // You must bind "core" object when registering an event.
    /** add event listeners */
    const self = this;
    listDiv
      .querySelectorAll('.se-btn-list')
      .forEach((btn: HTMLButtonElement) => {
        btn.addEventListener('click', self.onClick.bind(core));
      });

    // @Required
    // You must add the "submenu" element using the "core.initMenuTarget" method.
    /** append target button menu */
    core.initMenuTarget(this.name, targetElement, listDiv);
  },

  setSubmenu() {
    const listDiv = this.util.createElement('DIV');

    // @Required
    // A "se-submenu" class is required for the top level element.
    listDiv.className = 'se-submenu se-list-layer';
    snippetList.forEach(({label, value}) => {
      listDiv.innerHTML += `<button type="button" class="se-btn-list" value="${value}">${label}</button>`;
    });
    return listDiv;
  },

  onClick(e: any) {
    const {value} = e.target;
    const node = this.util.createElement('span');
    this.util.addClass(node, 'se-custom-tag');
    node.textContent = value;

    this.insertNode(node);
    const zeroWidthSpace = this.util.createTextNode(this.util.zeroWidthSpace);
    node.parentNode.insertBefore(zeroWidthSpace, node.nextSibling);

    // Get full contents and set it to the editor
    const htmlContent = this.getFullContents();
    this.setContents(htmlContent);
    this.submenuOff();
  },
};

export default snippetPlugin;
