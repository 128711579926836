import {constants} from '../../constants/common';
import {
  fetchManagedUsers,
  fetchUdfDetailsService,
  fetchUserByUserKey,
  fetchUsersByUserKey,
} from '../../services/users';
import {actionTypes} from '../constants/actionTypes';
import {updateEnableEnhancedAdmin} from './generalActions';

const {
  FETCHING_MANAGED_USERS,
  FETCHED_MANAGED_USERS,
  FETCHING_MANAGED_USERS_FAILED,
} = actionTypes;

export const actions = {
  FETCHING_MANAGED_USERS,
  FETCHED_MANAGED_USERS,
  FETCHING_MANAGED_USERS_FAILED,
};

export const getUserData = () => {
  return JSON.parse(sessionStorage.getItem('profile'));
};

export const getManagedUsers = (options, showSnackbar) => dispatch => {
  const {userKey} = options;
  const endpoint = `v4/api/manageduser/for/${userKey}`;
  dispatch({type: FETCHING_MANAGED_USERS});
  fetchManagedUsers(endpoint)
    .then(data => {
      dispatch({
        type: FETCHED_MANAGED_USERS,
        users:
          data.length > 0
            ? data.map(({mgdUserID, mgdUserKey, userKey: uKey, userName}) => ({
                mgdUserID,
                mgdUserKey,
                userKey: uKey,
                userName,
              }))
            : data,
        count: data.count,
        nextLink: data.nextLink,
        loading: false,
      });
      return data;
    })
    .catch(() => {
      showSnackbar('Failed to fetch managed users', constants.ERROR, 'error');
      dispatch({type: FETCHING_MANAGED_USERS_FAILED, loading: false});
    });
};

export const getUsersByKey =
  ({groupKey}, showSnackbar) =>
  dispatch => {
    dispatch({type: actionTypes.FETCHING_USER_LIST});
    fetchUsersByUserKey({groupKey})
      .then(res => {
        dispatch({
          type: actionTypes.FETCHED_USER_LIST,
          userListByGroup: res.data,
        });
        return res.data;
      })
      .catch(() => {
        showSnackbar('Failed to fetch user list', constants.ERROR, 'error');
        dispatch({type: actionTypes.FETCHING_USER_LIST_FAILED, loading: false});
      });
  };

export const getUserSettings = (options, showSnackbar) => dispatch => {
  dispatch({type: actionTypes.FETCHING_USER_SETTINGS});
  fetchUserByUserKey(options)
    .then(res => {
      const {data} = res;
      dispatch({type: actionTypes.FETCHED_USER_SETTINGS, userSettings: data});
      const profile = JSON.parse(sessionStorage.profile);
      const {user, features} = data;
      profile.isAdmin = user.adminUser === 1;
      profile.timeZoneId = user.timeZoneID;
      profile.userType = user.userType;
      window.sessionStorage.setItem('profile', JSON.stringify(profile));
      const enableEnhancedAdmin = features.find(
        feature => feature.featureId === 'EnableEnhancedAdmin',
      );
      if (enableEnhancedAdmin) {
        dispatch(updateEnableEnhancedAdmin(enableEnhancedAdmin.featureValue));
      }
      return res.data;
    })
    .catch(() => {
      showSnackbar('Failed to fetch user settings', constants.ERROR, 'error');
      dispatch({
        type: actionTypes.FETCHING_USER_SETTINGS_FAILED,
        loading: false,
      });
    });
};

export const fetchUdfDetails =
  ({groupKey}, showSnackbar) =>
  dispatch => {
    dispatch({type: actionTypes.FETCHING_UDF_DETAILS});
    return fetchUdfDetailsService(groupKey)
      .then(res => {
        const {data} = res;
        dispatch({type: actionTypes.FETCHED_UDF_DETAILS, udfDetails: data});
      })
      .catch(() => {
        showSnackbar('Failed to fetch UDF details', constants.ERROR, 'error');
        dispatch({
          type: actionTypes.FETCHING_UDF_DETAILS_FAILED,
        });
      });
  };
