import React, {forwardRef} from 'react';
import {Checkbox, CheckboxProps, FormControlLabel} from '@mui/material';
import {Controller, Control} from 'react-hook-form';

interface Props extends CheckboxProps {
  /**
   * Label to be displayed
   */
  label: string;
  /**
   * Name of the field (required by React Hook Form)
   */
  name: string;
  /**
   * Control object from React Hook Form
   */
  control: Control<any>;
}

/**
 * Checkbox component to show Checkbox with label and Controller.
 * More information available here https://mui.com/material-ui/react-checkbox/#label
 */

const ControlledCheckboxLabel = forwardRef<HTMLDivElement, Props>(
  ({label, name, control, ...checkboxProps}, ref) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({field}) => (
          <FormControlLabel
            control={
              <Checkbox
                {...field}
                {...checkboxProps}
                checked={field.value} // Bind checked state from Controller
                onChange={e => field.onChange(e.target.checked)} // Handle change via Controller
              />
            }
            label={label}
            ref={ref}
          />
        )}
      />
    );
  },
);

export default ControlledCheckboxLabel;
