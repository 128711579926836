import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from 'react';
import {Snackbar} from '../ui/feedback';
import {SnackbarType} from '../ui/feedback/snackbar/Snackbar';

const SnackbarContext = createContext<
  (message: string, title?: string, type?: SnackbarType) => void
>(() => {});

export const SnackbarProvider = ({children}: {children: ReactNode}) => {
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    title: string;
    type?: SnackbarType;
  }>({
    open: false,
    message: '',
    title: '',
    type: 'info',
  });

  const showSnackbar = useCallback(
    (message: string, title = '', type: SnackbarType = 'info') => {
      setSnackbar({open: true, message, title, type});
    },
    [setSnackbar],
  );

  const handleClose = () => {
    setSnackbar({...snackbar, open: false});
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbar.message}
        title={snackbar.title}
        type={snackbar.type || 'info'}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

/**
 * The SnackbarContext is a context that provides a function to show a snackbar message.
 * HOW TO USE:
 * 1. Wrap the root component with the SnackbarProvider.
 * 2. Use the useSnackbar hook to get the showSnackbar function. const showSnackbar = useSnackbar();
 * 3. Call the showSnackbar function with the message, title, and type. showSnackbar('Message', '', 'error');
 */
