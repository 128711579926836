import dayjs from 'dayjs'; // To use UTC

const insertDatePlugin = {
  // @Required
  // plugin name
  name: 'insertDate',

  // @Required
  // data display
  display: 'command',
  command: 'insert-date-plugin-command',

  // @Required
  // add function - It is called only once when the plugin is first run.
  add() {},

  action() {
    const editorEl = document.getElementsByClassName('sun-editor-editable')[0];
    if (editorEl) {
      try {
        // Focus on the editor to ensure the insertion happens at the right place
        (editorEl as HTMLElement).focus();

        // Get the current date and time using dayjs
        const formattedDate = dayjs().format('MM-DD-YYYY hh:mm A');

        // Get the current selection
        const selection = window.getSelection() ?? null;
        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);

          // Insert the formatted date at the current cursor position
          range.deleteContents(); // Optionally delete the selected text
          range.insertNode(document.createTextNode(formattedDate));
        } else {
          // If no selection, simply append the date at the end
          editorEl.innerHTML += ` ${formattedDate}:-`;
        }

        console.log('Date inserted into editor.');
      } catch (err) {
        console.error('Failed to insert date: ', err);
      }
    }
  },
};

export default insertDatePlugin;
