/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface DashboardState {
  selectedCollector: string;
  selectedCompany: string;
  selectedCurrency: string;
}

const initialState: DashboardState = {
  selectedCollector: '',
  selectedCompany: '',
  selectedCurrency: '',
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setSelectedCollector(state, action: PayloadAction<string>) {
      state.selectedCollector = action.payload;
    },
    setSelectedCompany(state, action: PayloadAction<string>) {
      state.selectedCompany = action.payload;
    },
    setSelectedCurrency(state, action: PayloadAction<string>) {
      state.selectedCurrency = action.payload;
    },
  },
});

export const {setSelectedCollector, setSelectedCompany, setSelectedCurrency} =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
